// WhatGPT3.jsx
import React, { useState, useRef, useEffect  } from 'react';
import Feature from '../../components/feature/Feature';
import Navbar from '../../components/navbar/Navbar';
import PopupContent from '../../containers/PopupContent/PopupContent';
import Animate from '../animate/Animate';

import reactLogo from '../../assets/react.svg';
import javascriptLogo from '../../assets/javascript.svg';
import cssLogo from '../../assets/css.svg';
import tailwindLogo from '../../assets/tailwind.svg';
import htmlLogo from '../../assets/html.svg';
import phpLogo from '../../assets/php.svg';






import './Services.css';

const Services = () => {



  const [showGoogleAdsPopup, setShowGoogleAdsPopup] = useState(false);
  const [showBingAdsPopup, setShowBingAdsPopup] = useState(false);
  const [showFacebookAdsPopup, setShowFacebookAdsPopup] = useState(false);
  const [showSEOPopup, setShowSEOPopup] = useState(false);
  const [showAnalyticsPopup, setShowAnalyticsPopup] = useState(false);

  const handleReadMoreClick = (service) => {
    const button = document.getElementById("main-contact-button-v1");
    switch (service) {
      case 'Google Ads':
        setShowGoogleAdsPopup(true);
        // Get a reference to the button element with id "main-contact-button"
        button.style.display = "none";
        break;
      case 'Bing Ads':
        setShowBingAdsPopup(true);
        button.style.display = "none";
        break;
      case 'Facebook Ads':
        setShowFacebookAdsPopup(true);
        button.style.display = "none";
        break;
      case 'SEO':
        setShowSEOPopup(true);
        button.style.display = "none";
        break;
      case 'Analytics and Measurement':
        setShowAnalyticsPopup(true);
        button.style.display = "none";
        break;
      default:
        break;
    }
  };

  const handleClosePopup = (service) => {
    const button = document.getElementById("main-contact-button-v1");
    switch (service) {
      case 'Google Ads':
        setShowGoogleAdsPopup(false);
       button.style.display = "flex";
        break;
      case 'Bing Ads':
        setShowBingAdsPopup(false);
        button.style.display = "flex";
        break;
      case 'Facebook Ads':
        setShowFacebookAdsPopup(false);
        button.style.display = "flex";
        break;
      case 'SEO':
        setShowSEOPopup(false);
        button.style.display = "flex";
        break;
      case 'Analytics and Measurement':
        setShowAnalyticsPopup(false);
        button.style.display = "flex";
        break;
      default:
        break;
    }
  };

  // Define the slide content for each service
  const googleAdsSlides = [
    {
      title: '',
      headline1: 'How does Google Ads work?',
      text1: "Advertising on Google Ads involves creating and displaying ads on Google's search engine and advertising network. Advertisers bid on relevant keywords, and when users search using those keywords, an auction determines which ads appear. Ad rank, based on bid amount and ad quality, determines ad placement. Advertisers only pay when users click on their ads (PPC), with costs varying based on competition and ad performance. Google Ads offers various ad formats, targeting options, and budget controls, allowing businesses to reach their target audience, increase visibility, and achieve specific advertising goals. Monitoring performance and optimizing campaigns ensure efficient use of advertising budgets and better results.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


      image1:  <img className="FOBM__IMAGE" src={require('../../assets/google-search.png')} alt="Image description" />,

      next_p: 'Next:',
      next: 'How does Ad Rank work? (2/10)',


    },
    {
      title: '',
      headline1: 'How does Ad Rank work?',
      text1: "",
      headline2: '',
      text2: "Ad Rank is a critical concept in Google Ads that determines the position and visibility of an advertiser's ad on the search engine results page or other Google ad placements. It is a score calculated by Google's ad auction system for each ad competing for a particular keyword or placement. Ad Rank takes into account two main factors: the Maximum CPC bid and the Ad Quality Score.",
      headline3: '',
      text3: '1: Maximum CPC Bid: This is the highest amount an advertiser is willing to pay for a click on their ad. When setting up a campaign, advertisers specify their maximum bid for each keyword or placement.',
      headline4: '',
      text4: '2: Ad Quality Score: Google assesses the quality and relevance of an ad based on its click-through rate (CTR), ad relevance to the keyword, the quality of the landing page, and other factors. A higher-quality ad generally results in a better Ad Quality Score.',
      headline5: '',
      text5: "The formula for calculating Ad Rank is: Ad Rank = Maximum CPC Bid × Ad Quality Score",
      headline6: '',
      text6: "Google uses Ad Rank to determine the ad's position on the search results page or other ad placements. Ads with higher Ad Ranks are more likely to appear in top positions, while those with lower Ad Ranks may appear lower on the page or not at all.",
      headline7: '',
      text7: 'The concept of Ad Rank creates a balance between ad relevance and bid amount. It encourages advertisers to create high-quality, relevant ads that provide value to users, rather than solely relying on higher bids to secure top positions. By considering both the bid and ad quality, Google aims to deliver the most relevant and valuable ads to its users while also rewarding advertisers who offer a positive user experience.',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: "Google Ads is Pay Per Click (PPC) (3/10)",
    },
 
    {

      title: 'Side one',
      headline1: 'Google Ads is Pay per Click (PPC)',
      text1: "Payment on Google Ads operates on a Pay-Per-Click (PPC) model. With PPC, advertisers only pay when users click on their ads, not when the ad is displayed (impressions). Here's how the payment process works on Google Ads:",
      headline2: '',
      text2: "Advertiser Sets a Bid: When setting up a Google Ads campaign, the advertiser specifies the maximum amount they are willing to pay for a click on their ad. This is known as the Cost-Per-Click (CPC) bid. The CPC bid represents the maximum amount the advertiser is willing to spend for a single click on their ad.",
      headline3: '',
      text3: "Ad Auction and Ad Rank: When a user performs a search using keywords relevant to an advertiser's campaign, Google runs an ad auction to determine which ads to display. The auction takes into account factors like the CPC bid, Ad Quality Score (based on ad relevance and performance), and other ad extensions.",
      headline4: '',
      text4: "Ad Display and Click: If the advertiser's ad wins the auction and has a higher Ad Rank compared to other ads, it will be displayed on the search engine results page or other Google ad placements. When a user clicks on the ad, they are directed to the advertiser's website or landing page.",
      headline5: '',
      text5: "Cost Incurred: The advertiser incurs a cost for the click, which is equal to the amount of the second-highest bid in the auction (or a minimum value known as the 'minimum bid') that is required to maintain the Ad Rank position. The actual CPC paid may be lower than the maximum CPC bid set by the advertiser, depending on the competition and Ad Quality Score.",
      headline6: '',
      text6: "Budget Control: Advertisers set a daily or monthly budget to control their ad spend. Once the budget is exhausted, the ads may stop showing until the next budget cycle begins. This way, advertisers have control over their advertising costs.",
      headline7: '',
      text7: "Performance Monitoring: Advertisers can track the performance of their ads through Google Ads' reporting and analytics tools. They can monitor clicks, impressions, conversions, and other metrics to assess the effectiveness of their campaigns.",
      headline8: '',
      text8: 'Overall, the PPC payment model in Google Ads allows advertisers to pay only when their ads generate actual clicks, making it a cost-effective way to reach potential customers and ensuring that advertisers get value for their advertising investment. It also encourages advertisers to optimize their ads to be more relevant and engaging to users, as this can improve Ad Rank and potentially lower the cost per click.',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Search Campaigns (4/10)',

    },
    {
      title: 'Side one',
      headline1: 'Google Search Ads',
      text1: "A Google Ads search campaign is a type of online advertising campaign that involves displaying text-based ads on Google's search engine results page when users search for specific keywords or phrases. Advertisers bid on relevant keywords and create concise ad copies, which are triggered to appear when users' search queries match the selected keywords. The ads are labeled as 'Ad,' and advertisers only pay when users click on their ads (Pay-Per-Click model). Search campaigns are designed to target users actively searching for products, services, or information, making them highly effective for businesses to reach potential customers with high purchase intent.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


      next_p: 'Next:',
      next: 'Google Shopping (5/10)',


    },
    {
      title: 'Side one',
      headline1: 'Google Shopping',
      text1: "Google Ads Shopping is an e-commerce advertising campaign type where advertisers showcase products with images, prices, and details directly on Google's search results. Advertisers provide a product data feed and create Shopping campaigns, linking them to their Merchant Center account. Shopping ads appear when users search for relevant products, displaying visual product listings with key details. Advertisers pay per click (PPC) and can track performance through Google Ads' reporting tools. Shopping campaigns efficiently attract potential customers and drive sales by offering visually appealing product ads to users with buying intent.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


      image1:  <img className="FOBM__IMAGE" src={require('../../assets/google-shopping.png')} alt="Image description" />,

      next_p: 'Next:',
      next: 'Performance Max (6/10)',


    },
    {
      title: 'Side one',
      headline1: 'Performance Max',
      text1: "Google Ads Performance Max is an advertising campaign type that uses machine learning and automation to optimize ad placements across various Google platforms, including Search, Display, YouTube, and Discover. Advertisers provide assets such as headlines, images, and descriptions, and Google's AI-driven algorithms automatically determine the best combinations and placements to maximize performance based on the advertiser's goals, such as conversions, clicks, or reach. Performance Max leverages Google's vast network and targeting capabilities to reach relevant audiences with a diverse range of ad formats. Advertisers set a budget, and the system dynamically allocates it to different platforms to achieve optimal results. This campaign type simplifies the campaign creation process while delivering comprehensive reach and performance for advertisers across the Google ecosystem.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


      image1:  <img className="FOBM__IMAGE" src={require('../../assets/performance-max.png')} alt="Image description" />,

      next_p: 'Next:',
      next: 'Google Display Ads (7/10)',


    },

    {
      title: 'Side one',
      headline1: 'Google Display Ads',
      text1: "Google Display Ads is a campaign type in Google Ads that allows advertisers to display visually appealing ads across a vast network of websites, apps, and videos affiliated with Google. These ads can be in the form of banner ads, image ads, responsive ads, and interactive ads. Advertisers can target specific audiences based on demographics, interests, behaviors, and contextual relevance, enabling them to reach the right users at the right time. Google Display Ads are effective for building brand awareness, reaching a broad audience, and engaging potential customers visually. The campaign's performance can be tracked and optimized using Google Ads' reporting and analytics tools, providing valuable insights to improve ad effectiveness and return on investment (ROI).",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'YouTube Ads (8/10)',



    },

    {
      title: 'YouTube Ads',
      headline1: 'YouTube Ads',
      text1: "YouTube Ads is a campaign type within Google Ads that allows advertisers to showcase video ads on YouTube, the world's largest video-sharing platform. YouTube Ads offer various ad formats, including skippable and non-skippable video ads, bumper ads, and video discovery ads. Advertisers can target their ads based on factors like demographics, interests, keywords, and video topics, ensuring their ads reach relevant audiences. YouTube Ads are effective for storytelling, brand awareness, and engaging users with video content. Advertisers only pay when users watch or interact with their ads, making it a cost-effective way to reach a massive audience. With YouTube's reporting and analytics tools, advertisers can track ad performance and optimize their campaigns to achieve better results and maximize their advertising goals on the platform.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


      image1:  <img className="FOBM__IMAGE" src={require('../../assets/youtube.png')} alt="Image description" />,

      next_p: 'Next:',
      next: 'App Campaigns (9/10)',


    },
    {
      title: 'App Campaigns',
      headline1: 'App Campaigns',
      text1: "App campaigns, also known as Universal App Campaigns (UAC), are a type of advertising campaign in Google Ads focused on promoting mobile apps. Leveraging Google's machine learning, app campaigns automatically target potential users across various Google platforms based on app-related data, interests, demographics, and behavior. Advertisers provide ad assets, and Google dynamically creates engaging ads for different placements. The goal is to drive app installations and user engagement, and advertisers are charged on a Cost-Per-Install (CPI) basis. App campaigns provide a convenient and effective way for app developers and businesses to reach their target audience, increase app visibility, and boost app adoption and usage.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


      image1:  <img className="FOBM__IMAGE" id="app-image" src={require('../../assets/app.png')} alt="Image description" />,

      next_p: 'Next:',
      next: 'Google Discovery Ads (10/10)',
      


    },
    {
      title: 'Google Discovery Ads',
      headline1: 'Google Discovery Ads',
      text1: "Discovery ads are a type of advertising campaign in Google Ads that allow advertisers to reach and engage users across Google's discovery platforms, including YouTube, Gmail, and the Discover feed on the Google app. Discovery ads are visually engaging and appear as personalized content within users' feeds, matching the look and feel of the platform. Advertisers provide high-quality images or carousel cards, headlines, and descriptions, and Google's machine learning optimizes the ad placements to reach users who are likely to be interested in the content. Discovery ads are effective for building brand awareness, reaching a broad audience, and driving user engagement with visually appealing and relevant content. Advertisers only pay when users click on the ads, making it a cost-effective way to connect with potential customers across multiple discovery platforms",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',
     
      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',


    },
  ];
  

  const bingAdsSlides = [
    {
      title: "Bing Ads",
      headline1: "Bing Ads",
      text1: "Welcome to our comprehensive guide on 'Unlocking Business Opportunities with Bing Ads.' As a leading digital marketing agency, we are excited to share with you the powerful features and benefits that Bing Ads can offer for your brand. In this guide, we'll explore the essentials of Bing Ads, from understanding its unique audience and advertising options to exploring how it complements your overall marketing strategy. By the end of this guide, you'll have the knowledge and tools to leverage Bing Ads effectively and achieve remarkable results for your business.",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: "Why Bing Ads Matters (2/8)",
    },
    {
    title: "Why Bing Ads Matters",
    headline1: "Why Bing Ads Matters",
    text1: "While Google Ads dominates the search engine advertising landscape, Bing Ads should not be underestimated. Bing is the second-largest search engine, reaching millions of users who may not be reachable through Google. Let's explore why Bing Ads matters for your marketing success:",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Tap into a Different Audience:",
    li2h: "Less Competition:",
    li3h: "Bing-Yahoo Partnership:",
    li4h: "",
    li5h: "",

    li1: "Bing attracts a unique user base, including older demographics and users from specific industries, giving you access to a distinct audience.",
    li2: "Due to its lower market share, Bing Ads often have less competition, resulting in potentially lower costs per click and increased visibility for your ads.",
    li3: "Bing Ads powers Yahoo's search advertising, extending your reach to a broader network of users.",
    li4: "",
    li5: "",

    next_p: 'Next:',
    next: "Understanding Bing Ads (3/8)",
  },
  {
    title: "Understanding Bing Ads",
    headline1: "Understanding Bing Ads",
    text1: "To excel with Bing Ads, it's essential to understand its key features and how it works. Here's an overview of the core elements:",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Bing Search Network:",
    li2h: "Bing Audience Network:",
    li3h: "Advertising Options:",
    li4h: "",
    li5h: "",

    li1: "Your ads appear on Bing's search results pages when users enter relevant keywords, reaching users actively seeking products or services like yours.",
    li2: "Extend your reach beyond search results to reach users on Bing partner sites, apps, and Microsoft properties.",
    li3: "Bing Ads offers various ad formats, including text ads, product ads, app install ads, and image ads, allowing you to create tailored campaigns.",
    li4: "",
    li5: "",

    next_p: 'Next:',
    next: "Keyword Research for Bing Ads (4/8)",
  },
  {
    title: "Keyword Research for Bing Ads",
    headline1: "Keyword Research for Bing Ads",
    text1: "",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Bing Keyword Planner:",
    li2h: "Focus on Long-Tail Keywords:",
    li3h: "Match Types:",
    li4h: "",
    li5h: "",

    li1: "Utilize Bing's Keyword Planner to find relevant keywords and estimate their search volume and performance.",
    li2: "Bing users often use more extended and specific search queries, so prioritize long-tail keywords to target niche audiences effectively.",
    li3: "Understand Bing's match types (broad, phrase, exact, and broad match modifier) to control how your ads match user queries.",
    li4: "",
    li5: "",

    next_p: 'Next:',
    next: "Optimizing Bing Ads Campaigns (5/8)",
  },
  {
    title: "Optimizing Bing Ads Campaigns",
    headline1: "Optimizing Bing Ads Campaigns",
    text1: "To maximize your ROI with Bing Ads, it's crucial to optimize your campaigns continuously. Here are some optimization tips:",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Quality Score:",
    li2h: "Device Targeting:",
    li3h: "Ad Extensions:",
    li4h: "",
    li5h: "",

    li1: "Similar to Google Ads, focus on ad relevance, landing page experience, and expected click-through rate to improve your Quality Score and lower costs.",
    li2: "Bing Ads allows you to adjust bids for different devices (desktop, tablet, mobile), enabling you to optimize for user behavior on each platform.",
    li3: "Leverage ad extensions, such as sitelinks, call extensions, and location extensions, to enhance your ads' visibility and provide additional information to users.",
    li4: "",
    li5: "",

    next_p: 'Next:',
    next: "Bing Shopping Campaigns (6/8)",
  },
  {
    title: "Bing Shopping Campaigns",
    headline1: "Bing Shopping Campaigns",
    text1: "Bing Shopping Campaigns are an effective way to showcase your products to potential customers actively searching for them. Here's how to make the most of Bing Shopping:",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Product Feed Optimization: ",
    li2h: "Merchant Promotions:",
    li3h: "Negative Keywords:",
    li4h: "",
    li5h: "",

    li1: "Ensure your product feed contains accurate and up-to-date information, including titles, descriptions, prices, and images.",
    li2: "Encourage click-throughs by adding special offers or promotions to your product listings.",
    li3: "Use negative keywords to exclude irrelevant searches and improve the relevance of your shopping ads.",
    li4: "",
    li5: "",

    next_p: 'Next:',
    next: "Bing Ad Extensions (7/8)",
  },
  {
    title: "Bing Ad Extensions",
    headline1: "Bing Ad Extensions",
    text1: "Bing Ads offers various ad extensions to enhance your ads and provide more information to users. Here are some key extensions to consider:",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Sitelink Extensions:",
    li2h: "Call Extensions:",
    li3h: "Location Extensions:",
    li4h: "",
    li5h: "",

    li1: "Direct users to specific pages on your website relevant to their search.",
    li2: "Allow users to call your business directly from the search results page.",
    li3: "Display your business address and phone number, making it easy for local customers to find you.",
    li4: "",
    li5: "",

    next_p: 'Next:',
    next: "Analyzing Bing Ads Performance (8/8)",
  },
  {
    title: "Analyzing Bing Ads Performance",
    headline1: "Analyzing Bing Ads Performance",
    text1: "To make data-driven decisions, you need to monitor and analyze your Bing Ads performance regularly. Here's how to measure success:",
    headline2: "",
    text2: "",
    headline3: "",
    text3: "",
    headline4: "",
    text4: "",
    headline5: "",
    text5: "",
    headline6: "",
    text6: "",
    headline7: "",
    text7: "",
    headline8: "",
    text8: "",
    headline9: "",
    text9: "",
    headline10: "",
    text10: "",

    li1h: "Bing Ads Reports:",
    li2h: "Conversion Tracking:",
    li3h: "",
    li4h: "",
    li5h: "",

    li1: "Utilize Bing's reporting tools to track key performance metrics, such as clicks, impressions, conversions, and more.",
    li2: "Set up conversion tracking to measure the effectiveness of your ad campaigns in driving desired actions.",
    li3: "",
    li4: "",
    li5: "",


  },
  
  ];

  const facebookAdsSlides = [
    {
      title: 'Facebook Ads Overview',
      headline1: 'Facebook Ads',
      text1: "Facebook Ads is the largest social media advertising platform globally, with over 2.8 billion monthly active users. Its immense user base and engagement rates make it an undeniable hub for businesses to showcase their products and services. Let's explore the core elements of Facebook Ads:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Massive User Base: ",
      li2h: "Diverse Ad Formats:",
      li3h: "",
      li4h: "",
      li5h: "",


      li1: 'Reach over 2.8 billion monthly active users on Facebook, offering unparalleled access to potential customers around the world.',
      li2: ' Utilize various ad formats such as image ads, video ads, carousel ads, and slideshow ads to create compelling campaigns that suit your marketing objectives.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Facebook Targeting Capabilitie (2/12)',
    },
    {
      title: 'Facebook Targeting Capabilities',
      headline1: 'Facebook Targeting Capabilities',
      text1: "One of the most significant advantages of Facebook Ads is its precise targeting capabilities. Gone are the days of casting wide nets and hoping for the best. With Facebook Ads, you can pinpoint your ideal customers based on various parameters:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Laser-Focused Targeting:",
      li2h: "Enhanced Relevance:",
      li3h: "",
      li4h: "",
      li5h: "",


      li1: ' Reach your ideal audience with precision by targeting demographics, interests, behaviors, and life events.',
      li2: 'Create highly relevant ads that resonate with specific user segments, increasing the likelihood of engagement and conversions.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Facebook Ad Formats (3/12)',
    },
    {
      title: 'Facebook Ad Formats',
      headline1: 'Facebook Ad Formats',
      text1: "Facebook Ads offer a versatile array of ad formats to help you craft compelling campaigns that resonate with your audience. Each format caters to different marketing objectives:",
      headline2: "",
      text2: "",
      headline3: '',
      text3: "",
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Eye-Catching Image Ads:",
      li2h: "Engaging Video Ads:",
      li3h: "Interactive Carousel Ads:",
      li4h: "Intriguing Slideshow Ads: ",
      li5h: "",

      li1: "Grab your audience's attention with visually appealing image ads that convey your brand's message effectively.",
      li2: " Use videos to tell captivating brand stories and evoke emotions, driving higher engagement and interaction.",
      li3: " Showcase multiple products, features, or benefits in a single ad, increasing engagement and click-through rates.",
      li4: "Create dynamic experiences with a mix of images, text, and sound, all while being cost-effective.",
      li5: "",

      next_p: 'Next:',
      next: 'Instagram Integration (4/12)',
    },
    {
      title: 'Instagram Integration',
      headline1: 'Instagram Integration',
      text1: "As part of the Facebook Ads platform, Instagram presents a powerful opportunity for visual storytelling and brand engagement:",
      headline2: "Instagram's Visual Appeal",
      text2: "As part of the Facebook Ads platform, Instagram opens doors to over 1 billion monthly active users, providing a visually immersive environment for brands to connect with audiences.",
      headline3: "Cross-Platform Synergy",
      text3: "Seamlessly extend your Facebook campaigns to Instagram, tapping into both platforms' user bases and maximizing your advertising efforts. ",
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: ' Facebook Pixel (5/12)',
    },
    {
      title: ' Facebook Pixel',
      headline1: ' Facebook Pixel',
      text1: "In the era of data-driven marketing, the Facebook Pixel is an invaluable tool that empowers you with insights into user behavior and campaign performance:",
      headline2: '',
      text2: "",
      headline3: "",
      text3: "",
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "The Power of Pixel:",
      li2h: "Precise Retargeting:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Track user actions and interactions on your website to gain valuable insights and improve ad targeting and retargeting.',
      li2: 'Use Pixel data to retarget website visitors with tailored ads, increasing the chances of conversion.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Facebook Custom Audiences (6/12)',
    },
    {
      title: 'Facebook Custom Audiences',
      headline1: 'Facebook Custom Audiences',
      text1: "One-size-fits-all marketing is a thing of the past. Facebook's Custom Audiences feature enables personalized outreach:",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Personalized Outreach: ",
      li2h: "Lookalike Audiences: ",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Utilize Custom Audiences by uploading customer email lists, phone numbers, or website visitors to create hyper-targeted ad campaigns.',
      li2: "Expand your reach to potential customers who share similar interests and characteristics with your existing audience using Facebook's Lookalike Audiences feature.",
      li3: '',
      li4: '',    
      li5: '',

      next_p: 'Next:',
      next: 'Detailed Analytics (7/12)',
    },
    {
      title: 'Detailed Analytics',
      headline1: 'Detailed Analytics',
      text1: "Success in digital advertising hinges on data-driven decision-making. Facebook Ads provide comprehensive analytics and reporting tools:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Data-Driven Decisions:",
      li2h: "Actionable Insights:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Measure key performance metrics, such as clicks, impressions, reach, conversions, and more, to evaluate the success of your ad campaigns.',
      li2: 'Gain valuable demographic and behavioral data to refine your targeting and ad creatives for improved performance.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'A/B Testing (8/12)',
    },
    {
      title: 'A/B Testing',
      headline1: 'A/B Testing',
      text1: "The road to success with Facebook Ads is paved with continuous improvement. A/B testing is a powerful strategy:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Optimize Performance:",
      li2h: "Continuous Improvement:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Conduct A/B tests on ad creatives, headlines, and calls-to-action to refine your approach based on data-driven insights.',
      li2: 'Implement learnings from A/B tests to optimize future campaigns and achieve better results over time.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Cost-Effectiveness (9/12)',
    },

    {
      title: 'Cost-Effectiveness',
      headline1: 'Cost-Effectiveness',
      text1: "Facebook Ads offer unparalleled budget flexibility, making them an attractive choice for businesses of all sizes:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Budget Flexibility:",
      li2h: "Competitive Advantage:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Set daily or lifetime budgets to have precise control over your ad spend.',
      li2: 'Compare the cost-effectiveness of Facebook Ads with traditional advertising channels to make informed budget decisions.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Campaign Objectives (10/12)',
    },
    {
      title: 'Campaign Objectives',
      headline1: 'Campaign Objectives',
      text1: "Every marketing campaign has unique goals, and Facebook Ads allows you to tailor your approach accordingly:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Tailored Objectives:",
      li2h: "Specific Goals:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Choose from a range of campaign objectives on Facebook Ads to align with specific marketing goals and drive focused results.',
      li2: 'Select the most relevant objective for your campaign to optimize ad delivery and performance.',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: "Ad Scheduling and Optimization (11/12)",
    },
    {
      title: 'Ad Scheduling and Optimization',
      headline1: 'Ad Scheduling and Optimization',
      text1: "Timing is a crucial factor in advertising success. Facebook Ads' ad scheduling and optimization options enable you to show your ads at the most opportune moments:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Strategic Timing:",
      li2h: "Delivery Optimization:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: 'Schedule your ads to align with peak audience activity times to maximize their visibility and effectiveness.',
      li2: "Facebook's delivery system optimizes ad distribution to reach users who are most likely to engage with your ads, maximizing impact.",
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Remarketing Opportunities (12/12)',
    },
    {
      title: 'Remarketing Opportunities',
      headline1: 'Remarketing Opportunities',
      text1: "Remarketing is a powerful strategy that allows you to re-engage with users who have previously interacted with your brand:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Reconnect with Visitors:",
      li2h: "Nurture Leads:",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "Use remarketing to re-engage website visitors who haven't taken the desired action, reminding them of your brand's value and encouraging conversions.",
      li2: 'Utilize remarketing to nurture leads and guide potential customers through the sales funnel, boosting conversions and customer loyalty.',
      li3: '',
      li4: '',
      li5: '',

     
  
    },
    
  ];

  const seoSlides = [

    {
      title: '',
      headline1: 'SEO',
      text1: "Welcome to our comprehensive guide on 'Mastering Search Engine Optimization (SEO).' As a leading digital marketing agency, we are thrilled to share with you the essential strategies and best practices that will help your website rank higher in search engine results. In this guide, we'll cover everything you need to know about SEO, from understanding the importance of keywords and on-page optimization to exploring the benefits of link building and technical SEO. By the end of this guide, you'll have the knowledge and tools to elevate your website's visibility, drive organic traffic, and achieve remarkable results through SEO.",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Importance of SEO (2/8)',
    },
    {
      title: 'Importance of SEO',
      headline1: 'Importance of SEO',
      text1: "In today's digital age, search engines play a crucial role in connecting businesses with their target audience. SEO is the practice of optimizing your website to rank higher in search engine results pages (SERPs). By investing in SEO, you can attract organic traffic, establish authority in your industry, and ultimately drive more conversions. Let's explore the importance of SEO for your online success:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Increased Visibility:",
      li2h: "Credibility and Authority:",
      li3h: "Cost-Effectiveness:",
      li4h: "",
      li5h: "",

      li1: "SEO enhances your website's visibility in search engines, making it easier for potential customers to find you when they search for relevant products or services.",
      li2: "High rankings in search results instill trust and confidence in your brand, positioning your business as an authoritative source in your industry.",
      li3: "Unlike paid advertising, SEO drives organic traffic, which means you don't pay for each click or impression. It's a cost-effective long-term strategy for sustained growth.",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: 'Keyword Research (3/8)',
    },
    {
      title: 'Keyword Research',
      headline1: 'Keyword Research',
      text1: "Keyword research forms the foundation of a successful SEO strategy. It involves identifying the search terms and phrases that your target audience uses to find products or services like yours. Here's why keyword research is vital:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Understanding User Intent:",
      li2h: "Targeting High-Value Keywords:",
      li3h: "Outranking Competitors:",
      li4h: "",
      li5h: "",

      li1: 'Keyword research helps you understand what your audience is searching for and what their intentions are. This insight allows you to create content that addresses their needs.',
      li2: 'By targeting relevant and high-value keywords, you increase the likelihood of attracting quality traffic that is more likely to convert',
      li3: 'Analyzing competitor keywords enables you to identify opportunities to outrank them in search results.',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'On-Page Optimization (4/8)',
    },
    {
      title: 'On-Page Optimization',
      headline1: 'On-Page Optimization',
      text1: "On-page optimization involves optimizing various elements on your website to make it more search engine-friendly and user-friendly. Here's how to optimize your on-page elements:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Title Tags and Meta Descriptions:",
      li2h: "Header Tags:",
      li3h: "Keyword-Optimized Content:",
      li4h: "URL Structure:",
      li5h: "",

      li1: "Craft compelling title tags and meta descriptions that include targeted keywords and encourage clicks from search results.",
      li2: "Use header tags (H1, H2, H3, etc.) to structure your content and make it easier for search engines and users to understand.",
      li3: "Create high-quality, relevant, and keyword-optimized content that provides value to your audience.",
      li4: "Ensure your URLs are clean, descriptive, and contain relevant keywords.",
      li5: "",

      next_p: 'Next:',
      next: 'Content Marketing and SEO (5/8)',
    },
    {
      title: 'Content Marketing and SEO',
      headline1: 'Content Marketing and SEO',
      text1: "Content marketing and SEO go hand in hand. Quality content not only engages your audience but also attracts valuable inbound links and social shares. Here's why content marketing is essential for SEO:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Link Building:",
      li2h: "User Engagement:",
      li3h: "Shareability: ",
      li4h: "",
      li5h: "",

      li1: "High-quality content attracts inbound links from authoritative websites, signaling to search engines that your content is valuable and worthy of higher rankings.",
      li2: "Engaging content keeps users on your site longer, reducing bounce rates and increasing the likelihood of conversions.",
      li3: "When your content is shareable and valuable, it increases the chances of social media shares, driving more traffic and exposure.",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: 'Technical SEO (6/8)',
    },
    {
      title: 'Technical SEO',
      headline1: 'Technical SEO',
      text1: "Technical SEO focuses on optimizing your website's technical aspects to improve its crawlability, indexation, and overall performance. Key elements of technical SEO include:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Site Speed:",
      li2h: "Mobile-Friendliness: ",
      li3h: "XML Sitemap:",
      li4h: "Robots.txt:",
      li5h: "",

      li1: 'Ensure your website loads quickly on both desktop and mobile devices, as site speed is a crucial ranking factor.',
      li2: 'Optimize your site for mobile devices to cater to the growing number of mobile users.',
      li3: "Create and submit an XML sitemap to search engines to help them understand your site's structure and content.",
      li4: "Use a robots.txt file to control which pages search engines should crawl and index.",
      li5: "",

      next_p: 'Next:',
      next: 'Local SEO (7/8)',
    },
    {
      title: 'Local SEO',
      headline1: 'Local SEO',
      text1: "Local SEO is vital for businesses targeting a specific geographic area. It involves optimizing your online presence to attract local customers. Here's how local SEO can benefit your business:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Google My Business (GMB):",
      li2h: "Local Citations:",
      li3h: "Local Content:",
      li4h: "",
      li5h: "",

      li1: 'Claim and optimize your GMB listing to appear in local search results and on Google Maps.',
      li2: "Ensure your business information is consistent across online directories, ensuring search engines can verify your business's legitimacy and location.",
      li3: "Create content that caters to local interests and needs, such as local events, news, and promotions.",
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'Measuring SEO Success (8/8)',
    },
    {
      title: 'Measuring SEO Success',
      headline1: 'Measuring SEO Success',
      text1: "To gauge the effectiveness of your SEO efforts, it's essential to track and measure key performance indicators (KPIs). Here are some crucial metrics to monitor:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Organic Traffic:",
      li2h: "Keyword Rankings:",
      li3h: "Bounce Rate and Dwell Time:",
      li4h: "Conversion Rate:",
      li5h: "",

      li1: 'Measure the number of visitors coming to your site from search engines.',
      li2: 'Track the rankings of your target keywords to assess your progress.',
      li3: 'Analyze bounce rate and dwell time to understand how engaging your content is to users.',
      li4: 'Measure the percentage of visitors who complete desired actions, such as making a purchase or filling out a contact form.',
      li5: '',

   
    },
   
  ];

  const analyticsSlides = [
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },
    {
      title: "",
      headline1: "",
      text1: "",
      headline2: "",
      text2: "",
      headline3: "",
      text3: "",
      headline4: "",
      text4: "",
      headline5: "",
      text5: "",
      headline6: "",
      text6: "",
      headline7: "",
      text7: "",
      headline8: "",
      text8: "",
      headline9: "",
      text9: "",
      headline10: "",
      text10: "",

      li1h: "",
      li2h: "",
      li3h: "",
      li4h: "",
      li5h: "",

      li1: "",
      li2: "",
      li3: "",
      li4: "",
      li5: "",

      next_p: 'Next:',
      next: '',
    },

  ];

  const linkedInAdsSlides = [
    {
      title: 'LinkedIn Ads Overview',
      headline1: 'LinkedIn Ads',
      text1: "LinkedIn Ads is a powerful platform for professional networking and advertising, with over 774 million users worldwide. It offers unique opportunities for businesses to connect with a highly targeted audience. Let's explore the key features of LinkedIn Ads:",
      headline2: '',
      text2: '',
      headline3: '',
      text3: '',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      headline6: '',
      text6: '',
      headline7: '',
      text7: '',
      headline8: '',
      text8: '',
      headline9: '',
      text9: '',
      headline10: '',
      text10: '',

      li1h: "Professional Audience:",
      li2h: "B2B Opportunities:",
      li3h: "Precise Targeting:",
      li4h: "Engagement Tools:",
      li5h: "Analytics & Insights:",

      li1: 'Connect with a professional and career-oriented audience, making it ideal for B2B marketing and recruitment.',
      li2: 'Leverage B2B advertising opportunities to reach decision-makers, business professionals, and industry-specific audiences.',
      li3: 'Target your ads with precision using job titles, company size, industry, and more to reach the right professionals.',
      li4: 'Utilize engagement tools like sponsored content, sponsored InMail, and dynamic ads to interact with your audience.',
      li5: 'Access detailed analytics and insights to measure campaign performance and make data-driven decisions.',

      next_p: 'Next:',
      next: 'LinkedIn Ad Formats (2/12)',
    },
    {
      title: 'LinkedIn Ad Formats',
      headline1: 'Sponsored Content',
      text1: "Sponsored content allows you to promote your organic LinkedIn posts to a larger and more targeted audience. It appears directly in users' feeds and can include articles, images, videos, and carousel ads.",
      headline2: 'Sponsored InMail',
      text2: 'Sponsored InMail lets you send personalized messages directly to users’ LinkedIn inboxes. This format is ideal for personalized invitations, event promotions, and more.',
      headline3: 'Display Ads',
      text3: 'Display ads appear on the right side of the LinkedIn desktop interface. They can include images, text, and a call to action, making them a great option for brand awareness.',
      headline4: 'Dynamic Ads',
      text4: 'Dynamic ads personalize content for each viewer, displaying their LinkedIn profile image alongside your ad. They are highly engaging and effective for targeting professionals.',
      headline5: 'Carousel Ads',
      text5: 'Carousel ads allow you to showcase multiple images or videos within a single ad, each with its own link. They are perfect for telling a visual story or presenting a range of products or services.',

      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Targeting (3/12)',
    },
    {
      title: 'LinkedIn Targeting',
      headline1: 'Precise Audience Targeting',
      text1: 'LinkedIn offers granular targeting options, allowing you to reach your ideal audience based on factors like job title, company size, industry, skills, and more. This precision ensures that your ads are seen by the right professionals.',
      headline2: 'Matched Audiences',
      text2: 'With matched audiences, you can retarget website visitors, upload contact lists, and target account lists, increasing the relevance of your ads and conversion rates.',
      headline3: 'Lookalike Audiences',
      text3: 'Expand your reach by creating lookalike audiences that resemble your existing customers. LinkedIn will find similar professionals who are likely to be interested in your products or services.',
      headline4: 'Demographic Targeting',
      text4: 'Demographic targeting allows you to focus on specific demographics such as age, gender, education, and more. This feature helps tailor your ads to different audience segments.',
      headline5: 'Interest-Based Targeting',
      text5: 'Reach professionals based on their interests and activities on LinkedIn. This targeting method is effective for products or services catering to specific professional interests.',

      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Ad Auction (4/12)',
    },
    {
      title: 'LinkedIn Ad Auction',
      headline1: 'Bid Strategies',
      text1: "In the LinkedIn ad auction, you can choose from different bid strategies, such as cost-per-click (CPC) or cost-per-impression (CPM). Set your bid amounts based on your budget and campaign objectives.",
      headline2: 'Bid Adjustments',
      text2: 'Utilize bid adjustments to optimize your bids for specific audience segments, devices, and ad formats. This allows for more precise control over your ad spend.',
      headline3: 'Ad Relevance',
      text3: 'LinkedIn rewards ads with high relevance to their target audience by offering better placement and lower costs. Creating engaging and relevant ads is key to success in the auction.',
      headline4: 'Ad Quality',
      text4: 'Ad quality is another important factor. High-quality ads not only perform better but also help reduce costs. LinkedIn values ads that provide value to its users.',
      headline5: 'Ad Rank',
      text5: "Your ad's rank in the auction is determined by factors like bid amount, ad relevance, and ad quality. The ad with the highest ad rank gets the top placement.",

      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Ad Budgeting (5/12)',
    },
    {
      title: 'LinkedIn Ad Budgeting',
      headline1: 'Budget Flexibility',
      text1: 'LinkedIn Ads offer flexibility in setting your advertising budget. You can choose daily or lifetime budgets, ensuring that you have control over your spending.',
      headline2: 'Bid Strategies',
      text2: 'Select the bid strategy that aligns with your campaign goals. Options include automated bid optimization and manual bidding to maximize ad performance.',
      headline3: 'Cost Control',
      text3: 'Monitor and control your ad spend by setting maximum bids, daily caps, and lifetime budgets. This helps you stay within your financial limits.',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',

      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Ad Reporting (6/12)',
    },
    {
      title: 'LinkedIn Ad Reporting',
      headline1: 'Campaign Performance',
      text1: 'Access detailed reports on your ad campaigns, including metrics such as clicks, impressions, click-through rates, and conversions. Analyze which campaigns are performing best.',
      headline2: 'Audience Insights',
      text2: 'Gain valuable demographic insights about your target audience. Understand their job titles, industries, locations, and more to fine-tune your targeting.',
      headline3: 'Conversion Tracking',
      text3: 'Track and measure the actions users take after clicking on your ads. Determine how effectively your ads are driving lead generation and conversions.',
      headline4: 'A/B Testing Results',
      text4: 'Review the results of A/B tests on your ad creatives, headlines, and calls to action. Use this data to optimize your ads for better performance.',
      headline5: 'Ad Spend Analysis',
      text5: 'Analyze your ad spend and ROI to determine the cost-effectiveness of your campaigns. Adjust budgets and strategies accordingly.',
      
      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Ad Testing (7/12)',
    },
    {
      title: 'LinkedIn Ad Testing',
      headline1: 'A/B Testing',
      text1: 'Implement A/B tests on different elements of your ads, such as headlines, visuals, and calls to action. Use data-driven insights to refine your ad strategies.',
      headline2: 'Ad Copy Testing',
      text2: 'Test variations of your ad copy to identify which messages resonate best with your audience. Adjust the tone, style, and content for optimal engagement.',
      headline3: 'Landing Page Testing',
      text3: 'Evaluate the effectiveness of your landing pages by running tests on layout, forms, and content. Optimize the user experience for better conversions.',
      headline4: 'Device Testing',
      text4: 'Assess the performance of your ads on different devices (desktop, mobile, tablet). Optimize your ad content and design to suit user behavior on each platform.',
      headline5: 'Time and Day Testing',
      text5: 'Experiment with different times and days for ad delivery. Determine when your target audience is most active and engaged for improved results.',
      
      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Remarketing (8/12)',
    },
    {
      title: 'LinkedIn Remarketing',
      headline1: 'Retargeting Website Visitors',
      text1: 'Use LinkedIn\'s retargeting capabilities to reconnect with users who have visited your website. Deliver relevant ads to encourage them to return and convert.',
      headline2: 'Engaging with Leads',
      text2: 'Nurture your leads through remarketing efforts. Provide valuable content and offers to guide potential customers through the sales funnel and boost conversions.',
      headline3: 'Dynamic Remarketing',
      text3: 'Create dynamic remarketing ads that showcase specific products or services that users have previously viewed on your website. Re-engage them with personalized content.',
      headline4: '',
      text4: '',
      headline5: '',
      text5: '',
      
      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Lead Scoring (9/12)',
    },
    {
      title: 'LinkedIn Lead Scoring',
      headline1: 'Lead Scoring Models',
      text1: 'Implement lead scoring models to assign values to your leads based on their engagement and behavior. Identify the most promising leads for your sales team to focus on.',
      headline2: 'Behavior Tracking',
      text2: 'Track user interactions with your content and website. Monitor actions like downloading resources, visiting key pages, and engaging with emails.',
      headline3: 'Lead Quality Assessment',
      text3: 'Assess lead quality by evaluating demographic data and behavioral patterns. Determine which leads are more likely to convert and make purchase decisions.',
      headline4: 'Sales-Ready Leads',
      text4: 'Identify leads that are sales-ready and those that need further nurturing. Streamline your sales process by focusing on leads that are closer to making a purchase.',
      headline5: '',
      text5: '',
      
      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Ad Best Practices (10/12)',
    },
    {
      title: 'LinkedIn Ad Best Practices',
      headline1: 'Compelling Visuals',
      text1: 'Use high-quality images and videos in your ads to capture the attention of your audience. Visuals are powerful tools for conveying your brand and message effectively.',
      headline2: 'Clear and Concise Copy',
      text2: 'Craft clear and concise ad copy that communicates your message effectively. Avoid jargon and focus on the value you provide to the audience.',
      headline3: 'Strong Call to Action (CTA)',
      text3: "Include a strong and compelling CTA in your ads. Encourage users to take action, whether it's visiting your website, downloading a resource, or contacting you.",
      headline4: "Mobile Optimization",
      text4: 'Ensure that your ads are optimized for mobile devices. Many LinkedIn users access the platform via mobile, so mobile-friendly ads are essential for engagement.',
      headline5: 'Ad Rotation',
      text5: 'Regularly update your ad creative and copy to combat ad fatigue and maintain user engagement. A/B test different elements to optimize performance.',
      
      li1h: 'Consistent Branding',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: 'Maintain consistent branding in your ads to reinforce your company\'s identity and message. Consistency builds trust and recognition among your audience.',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: 'Next:',
      next: 'LinkedIn Ad Challenges (12/12)',
    },
    {
      title: 'LinkedIn Ad Challenges',
      headline1: 'High CPC',
      text1: 'LinkedIn Ads can have a higher cost per click (CPC) compared to other platforms. Be prepared for competitive bidding and ensure your ad quality is high.',
      headline2: 'Ad Fatigue',
      text2: 'Users may experience ad fatigue if they see the same ads repeatedly. To combat this, regularly refresh your ad content and creative to maintain engagement.',
      headline3: 'Ad Approval Process',
      text3: 'LinkedIn has a stringent ad approval process. Ensure your ads comply with their policies to avoid delays in campaign launch.',
      headline4: 'Ad Content Quality',
      text4: 'Create high-quality ad content that provides value to your audience. This not only improves ad performance but also enhances your brand reputation.',
      headline5: 'Monitoring and Optimization',
      text5: 'Regularly monitor your campaigns and optimize them based on data and insights. Be prepared to make adjustments to improve performance.',
      
      li1h: '',
      li2h: '',
      li3h: '',
      li4h: '',
      li5h: '',

      li1: '',
      li2: '',
      li3: '',
      li4: '',
      li5: '',

      next_p: '',
      next: '',
    }
];


const [buttonStyle, setButtonStyle] = useState({ display: 'none' });
const navbarRef = useRef(null);


useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      // The Navbar is inside the viewport
     if (buttonStyle.display === 'none') {
      setButtonStyle({ display: 'flex' });

 
     } else {
      setButtonStyle({ display: 'flex' });
     }
    }
  });

  if (navbarRef.current) {
    observer.observe(navbarRef.current);
  }

  return () => {
    if (navbarRef.current) {
      observer.unobserve(navbarRef.current);
    }
  };
}, []);


  return (

    
    <div className="services section__margin" id="services">
      <div className="knap">
             <button id="main-contact-button-v1" type="button" style={buttonStyle} ><a href="#contact">Contact Us</a></button>
             </div>
      <div className="services-feature">
 
      </div>

      <div id="abc">
      <div className="services-heading" >
        <Animate>

          <div className="animatin-div">
        <h1 className="gradient__text" ref={navbarRef} >
          Our services reflect the most effective skills and tools to achieve results online
        </h1>

        </div>

        </Animate>
      </div>
      </div>

      <div className="services-container">
      <div>
          <Animate>
            <div className="animation-div">
          <Feature
            title="Web Development"
            text="User experience is key in developing apps, websites, landingpages. We specialize in developing digital user experience with an eye on digital marketing performance. We also have a strong sense of orginzation and have a drive for building web solutions which intergrates well with your business needs. "
          />
          <div>
          <img src={htmlLogo} className="web-product-logo" alt="html logo" />
          <img src={cssLogo} className="web-product-logo" alt="css logo" />
          <img src={tailwindLogo} className="web-product-logo" alt="tailwind logo" />
          <img src={reactLogo} className="web-product-logo" alt="react logo" />
          <img src={javascriptLogo} className="web-product-logo" alt="javascript logo" />
          <img src={phpLogo} className="web-product-logo" alt="javascript logo" />

          </div>

         <a href="https://fullofbeansmedia.com/web-development.html"> <button className="read__more" id="read__more__webdev">Read More</button></a>
                    {/*
                     <button className="read__more" onClick={() => handleReadMoreClick('Google Ads')}>
          Læs mere
          </button>
          */ }
          </div>
          </Animate>
        </div>
        <div>
          <Animate>
            <div className="animation-div">
          <Feature
            title="Google Ads"
            text="Drive targeted traffic, increase brand visibility, and maximize ROI with our expert Google Ads campaign management. Our team will optimize your ads, conduct thorough keyword research, and fine-tune targeting to ensure your ads reach the right audience. With compelling ad copies and continuous monitoring, we'll help you achieve optimal performance and cost-effectiveness. Trust  our  certified professionals to deliver tailored campaigns aligned with your business objectives, driving tangible results and growth."
          />
         <a href="https://fullofbeansmedia.com/google-ads.html"> <button className="read__more">Read More</button></a>
                    {/*
                     <button className="read__more" onClick={() => handleReadMoreClick('Google Ads')}>
          Læs mere
          </button>
          */ }
          </div>
          </Animate>
        </div>
        <div>
          <Animate>
          <Feature
            title="Bing Ads"
            text="Expand your online presence beyond Google with strategic Bing Ads campaigns. Reach a wider audience and drive targeted traffic to your website. Our experts will optimize your ads for Bing's search network, increasing brand visibility and conversions. With expert campaign management and continuous performance optimization, we'll maximize your ROI and help you tap into Bing's unique user base to connect with potential customers and grow your business."
          />
    <a href="https://fullofbeansmedia.com/bing-ads.html"> <button className="read__more">Read More</button></a>
          {/*   <button className="read__more" onClick={() => handleReadMoreClick('Bing Ads')}>
          Læs mere
          </button>*/ }
          </Animate>
        </div>
        <div>
        <Animate>
          <Feature
            title="Facebook Ads"
            text="Connect with your audience, boost brand awareness, and drive conversions through our targeted Facebook and Instagram ad campaigns. Our team will develop visually appealing ad creatives and leverage Meta's powerful targeting options to reach the right people based on demographics, interests, and behaviors. Through continuous monitoring and optimization, we ensure your ads deliver the best possible results. Maximize your ROI and expand your online presence with our effective Meta Ads strategies."
          />
         <a href="https://fullofbeansmedia.com/facebook-ads.html"> <button className="read__more">Read More</button></a>
          {/*<button className="read__more" onClick={() => handleReadMoreClick('Facebook Ads')}>
          Læs mere
          </button>*/ }
          </Animate>
        </div>
        <div>
        <Animate>
          <Feature
            title="SEO"
            text="Improve your website's visibility and organic search rankings with our expert SEO strategies. Our team enhances on-page elements, conducts keyword optimization, and implements effective link-building techniques to boost your search engine rankings. By driving targeted organic traffic, increasing brand authority, and outranking competitors, we help you achieve long-term success. Stay ahead with our data-driven optimizations, comprehensive audits, and staying up-to-date with SEO trends and algorithm changes."
          />
            <a href="https://fullofbeansmedia.com/seo.html"> <button className="read__more">Read More</button></a>

{/* <button className="read__more" onClick={() => handleReadMoreClick('SEO')}>
Læs mere
</button>*/ }
          </Animate>
        </div>
        <div>
        <Animate>
          <Feature
            title="LinkedIn Ads"
            text="For advertisers looking to connect with professionals, LinkedIn Ads is your indispensable tool. Reach decision-makers, foster B2B relationships, and amplify brand visibility with precision targeting, engaging ad formats, and ongoing optimization. Our expertise ensures your campaigns align with your goals and budget, making LinkedIn Ads the key to success in the professional realm."
          />
           <a href="https://fullofbeansmedia.com/linkedin-ads.html"> <button className="read__more">Read More</button></a>

{/*  <button
className="read__more"
onClick={() => handleReadMoreClick('Analytics and Measurement')}
>
Læs mere
</button>*/ }
          </Animate>
        </div>
      </div>

      {showGoogleAdsPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <PopupContent
              title="Google Ads"
        
              slides={googleAdsSlides}
              onClose={() => handleClosePopup('Google Ads')}
            />
          </div>
        </div>
      )}

      {showBingAdsPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <PopupContent
              title="Bing Ads"
              slides={bingAdsSlides}
              onClose={() => handleClosePopup('Bing Ads')}
            />
          </div>
        </div>
      )}

      {showFacebookAdsPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <PopupContent
              title="Facebook Ads"
              slides={facebookAdsSlides}
              onClose={() => handleClosePopup('Facebook Ads')}
            />
          </div>
        </div>
      )}

      {showSEOPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <PopupContent
              title="SEO"
              slides={seoSlides}
              onClose={() => handleClosePopup('SEO')}
            />
          </div>
        </div>
      )}

      {showAnalyticsPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <PopupContent
              title="Linkedin Ads"
              slides={linkedInAdsSlides}
              onClose={() => handleClosePopup('Analytics and Measurement')}
            />
          </div>
        </div>
      )}
    </div>
    

  );
};

export default Services;
